<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'dark'"
    :color="SidebarColor"
    mobile-breakpoint="970"
    clipped
    :right="$vuetify.breakpoint.smAndDown"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item two-line class="px-0">
        <v-list-item-avatar size="30">
          <v-icon :color="user.iconcolor" large>mdi-account-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle class="caption">{{
            user.id
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!---USer Area -->
      <!---Sidebar Items -->
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :active-class="`primary--text`"
        link
        :to="item.to ? item.to : ''"
        :href="item.to ? '' : item.href"
        :target="item.to ? '' : '_blank'"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-show="user.authority.usrmng && !$vuetify.breakpoint.smAndDown"
        to="/usrmng"
        :active-class="`primary--text`"
        link
      >
        <v-list-item-icon>
          <v-icon>mdi-account-edit</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>ユーザ管理</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
    </v-list>
    <v-btn text color="red" absolute right @click="logout()">ログアウト</v-btn>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import menuList from "@/menu_list.json";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    user: {
      id: "",
      iconcolor: "",
      name: "",
      authority: [],
    },
    items: menuList,
  }),
  methods: {
    logout() {
      axios.get(process.env.VUE_APP_API_URL + "/logout").then((response) => {
        if (response.data.result == "OK") {
          this.$router.push("/login");
        }
      });
    },
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_API_URL + "/sidebar-userinfo")
      .then((response) => {
        if (
          response.data.result == "NG" ||
          response.data.result == "SESSION_OUT"
        ) {
          if (location.pathname) {
            var param =
              "?redirect=" +
              location.pathname +
              location.search.replaceAll("/", "%2F");
          }
          this.$router.push("/login" + param);
        } else {
          this.user = response.data;
        }
      });
  },
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  watch: {},
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    min-height: 35px;
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .v-list-item__icon i {
    width: 20px;
  }
  .icon-size .v-list-group__items i {
    width: 16px;
    font-size: 16px;
  }
  .profile-bg {
    &.theme--dark.v-list-item:not(.v-list-item--active):not(
        .v-list-item--disabled
      ) {
      opacity: 1;
    }
    .v-avatar {
      padding: 15px 0;
    }
  }
  .theme--dark.v-list-item:not(.v-list-item--active):not(
      .v-list-item--disabled
    ) {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

.theme--dark.v-navigation-drawer {
  background-color: #242a33 !important;
}
</style>