<template>
  <v-app id="inspire">
    <Header v-model="expandOnHover"></Header>
    <Sidebar :expand-on-hover.sync="expandOnHover"></Sidebar>
    <v-main :class="animate">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Layout",

  components: {
    Header,
    Sidebar,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
    animate: "",
  }),
  computed: {
    ...mapState(["Customizer_drawer"]),
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
  },
  watch: {
    $route(oldPath, newPath) {
      if (oldPath.path != newPath.path) {
        this.animate = "animate__animated animate__fadeIn";
        setTimeout(() => {
          this.animate = "";
        }, 500);
      }
    },
  },
};
</script>
<style>
</style>