<template>
  <v-app-bar app clipped-left color="white" light>
    <v-toolbar-title class="align-center d-flex">
      <v-img
        @click="$router.push('/top')"
        style="cursor: pointer"
        height="50"
        width="180"
        src="@/assets/logo.svg"
      />
    </v-toolbar-title>
    <v-row justify="end">
      <v-app-bar-nav-icon
        class="d-block d-md-none"
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
    /></v-row>
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    userprofile: [
      { title: "My Profile" },
      { title: "My Balance" },
      { title: "Inbox" },
      { title: "Account Setting" },
      { title: "Logout" },
    ],
    href() {
      return undefined;
    },
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
  },
};
</script>
<style>
.v-app-bar__nav-icon {
  display: none;
}
</style>